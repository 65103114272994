import axios from "axios";
import Sunset from "@/libs/sunset";
import { md5AndRSA, queryURLParams } from './md5';
import httpErrorHandler from './httpErrorHandler'
export default (window.$http = function(options) {
    var method = (options.type || options.method || "get").toLowerCase();
    var data = options.data;
    // if (data && (method == 'post' || method == 'put' || method == 'patch')) {
    //     data = JSON.stringify(data);
    // }
    var config = {
        baseURL: process.env.VUE_APP_BASE_URL, //配置固定域名 'http://43.140.247.202:10010'
        url: options.url,
        method: method,
        data: data
    };
    // config.url = config.url.replace('vehicle_service','pvd_vehicle_service'); //换车辆服务名
    //替换路径参数
    if (data) {
        config.url = config.url.replace(/{\w+}/g, function(v) {
            var key = v.substring(1, v.length - 1);
            var value = data[key];
            delete data[key];
            return value;
        });
    }
    var headers = {};
    if (method == "get") {
        config.params = data;
    }
    // 后加的
    if (method == "post" && options.params) {
        config.params = options.params;
    }
    if (options.formdata) {
        config.transformRequest = [
            function(data) {
                let ret = "";
                for (let it in data) {
                    if (data[it] !== void 0 && data[it] !== null) {
                        ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                    }
                }
                return ret;
            }
        ];
        headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
    } else if (options.multipart) {
        let fromData = new FormData();
        for (let it in data) {
            if (data[it] !== void 0 && data[it] !== null) {
                fromData.append(it, data[it])
            }
        }
        config.data = fromData;
        headers["Content-Type"] = "multipart/form-data";
    } else {
        headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    headers.token = Sunset.getCookie("Admin-Tokenchangexin-test");

    // headers.token = "8386ac67-37ef-d1a7-6dc8-009afb501ef7"


    config.headers = headers;
    if (options.responseType) {
        config.responseType = options.responseType;
    }
    // 获取get url?后的参数
    const getParamsObj = queryURLParams(config.url);

    // todo获取请求参数
    var requestData = JSON.parse(JSON.stringify(Object.assign(config.params || {}, config.data || {}, getParamsObj || {})));
    let parm = md5AndRSA(requestData);

    //! 将随机字符串(MD5密钥)、时间戳、加密参数  添加到请求头中
    config.headers['nonce'] = parm.Data.nonce;
    config.headers['timestamp'] = parm.Data.timestamp;
    config.headers['sign'] = parm.sign;
    return axios(config).then(res => {
        // console.timeEnd(config.url); 
        var result = res.data;
        if (result.code) {
            if (result.code ==  1 || result.code == "310000") {
                return result;
            } else if (result.code === 4001) {
                console.log(result, '4001 return');
                //4001 跳转创作者平台登录
                setTimeout(() => {
                    window.open(`${process.env.VUE_APP_WORK_PATH}/#/login?open_login=true&name=${location.href}`, '_self');
                }, 1000)
                // window.open("http://localhost:8081/#/login?open_login=true&name=changexinWeb", '_self');
                // $sysName == 'viua' ? (window.location = `${location.origin}/login.html`) : $router.push("/login");
                return false;
            } 
            // else if (result.code == 204) {
            //     return result.data = null;
            // } else if (result.code === 401) {
            //     $tip("登录超时");
            //     //登出时  到一所门户
            //     window.open("http://14.29.67.234:8080/mhWebSite/web/home/home.jsp","_self");
            //     $sysName == 'viua' ? (window.location = `${location.origin}/login.html`) : $router.push("/login");
            // } else if (result.code === 520) {
            //     httpErrorHandler(result);
            // } else if (result.code === 500 && !result.message) {
            //     $tip("服务异常", "error");
            //     throw err;
            // } else {
            //     var err = new Error(result && result.message);
            //     err.code = result.code;
            //     err.message = (result.data && result.data.message) || result.message;
            //     if (err.message) {
            //         $tip(err.message, "error");
            //     }
            //     throw err;
            // }
        } else {
            return result;
        }
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            // $tip("登录超时");
            //登出时  到一所门户
           
        }
        if (error.response && error.response.status === 500) {
            if (error.response.data.code === 520) {
               
            }
            if (error.response.data.message) {
                // $tip(error.response.data.message, "error");
            }
        }
        if (error.response && error.response.status === 503) {
            if (error.response.data.message) {
                console.error(error.response.data.message, "error");
            }
        }
        if (error.code === "ECONNABORTED") {
            console.error(`-------------请求地址${options.url}超时断开连接，时长${options.timeout}s`, );
        }
        throw error;
    })
});