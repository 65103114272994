// +----------------------------------------------------------------------
// | quickadmin框架 [ quickadmin框架 ]
// +----------------------------------------------------------------------
// | 版权所有 2020~2022 南京新思汇网络科技有限公司
// +----------------------------------------------------------------------
// | 官方网站: https://www.quickadmin.icu
// +----------------------------------------------------------------------
// | Author: mj <931982149@qq.com>
// +----------------------------------------------------------------------
// | Description: 全局函数
// +----------------------------------------------------------------------
/**
 * 统一处理：格式化查询参数,只支持到二级
 * @param {*} params 如：id: { value: undefined, op: '=' }
 * @returns {op:{},fiter:{}}
 */
 export function formatQueryParams(data) {
    let search = {};
    let need_params = {};
    let need_op = {};
    filter(data);
  
    function filter(data, ckey) {
      for (let key in data) {
        if (!data[key].hasOwnProperty('value')) {
          filter(data[key], key);
        } else {
          if (data[key]['value'] == undefined || data[key]['value'] == null) continue;
          if (JSON.stringify(data[key]['value']) === '[]') continue;
  
          if (ckey) {
            need_params[`${ckey}.${key}`] = data[key].value;
            need_op[`${ckey}.${key}`] = data[key].op;
          } else {
            need_params[key] = data[key].value;
            need_op[key] = data[key].op;
          }
        }
      }
    }
  
    search.filter = JSON.stringify(need_params);
    search.op = JSON.stringify(need_op);
    return search;
  }