import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
  '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5lkUkMX8clKUGtmjqJ3iGPjEK63xBXHwlNx0I9US9SJBcey328H7XwDgpWHRR54CQYhg5d7l6kT8hp46mYlOSHX7oYGVsbCdxY5nPg0xjA/gjujxqaKuiKti6gW1LtQF6WCH8ekgqumIIw5B0rGTMjet6wXD8lsKFvFm6XPwefV8OIoLuvwpLdF4xG4DD4rZ4lRCPyNCnYmaweIWrSoba1+9Wwatp65zYJ3GD8dem/eWhE9C1nuOBsoVAdT5FWHWq9vV7Y4766GlPvRtBWzYzW8wkjXnQpbKD5OxmLC6ywQPwsrml3s6YUfc1JFcISU+YgCKGKGUpd1xNaXShd/ZvwIDAQAB-----END PUBLIC KEY-----';

const privateKey =
  'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAPt3n6rSF7ctnMCv\n' +
  '6+qXbM6B//T+Z7Y/KLeiSyMVXr4wzFsD3msgq3rpAwDlNbBHK/ub4LHVeLX8cKGM\n' +
  'e1CZxmWCQ0jgiPFuclSXiRBdykswLR1sr1LUJFHVuY13GkbSaA/dxOEzGB3tRgyD\n' +
  'zRZ+sQrb0rSIJWjs9Txx1oD/DqRfAgMBAAECgYACIyEjRYfYEbYsWRJrOwA8vRQc\n' +
  '0/CzttO0w9dZo/vbJ/UErQK97FgowkQ5RLlxFxnDmK6PNF5DRbL7XINLyxLJk8K7\n' +
  'R3U3zdqwpyuZ37oQfbpLZtTuUwLb5znAd6xoKwr/vuKMfNielNwTuX/nijZFvHTX\n' +
  'n8nteVxU7Z5gr7BQyQJBAP9KYRuSiTCgJIIamwY19eHUyjqfXrVsGxI0NjMl1bM0\n' +
  'DrgjSF/DqGQn22QnWvE3y5GbXY6M5sfEMfO8fiEXcnUCQQD8KoY+/S5MTSD7HgHU\n' +
  'ns3RblOY3Gf3kaV9XB4UNrKjfhkZi9MDiglO57Cg94YvV0KhvCDpQYWwiof8z9/o\n' +
  'E3kDAkAnoqWnAT45hXOFm5ncAf613DK1nU63/hvJ3L9Ze5NNKlTqs9elvpvjSOjg\n' +
  'CNguwUnixmtlGW0vML/MpsQyYRCNAkAeoafHFQg/uorMFlwQkO3dh/JdukBi4W1h\n' +
  '6gO5Xt+aXqH76XFLl8QDsr9Q/mBtdcMhVHIHA6+Sv3E/c5z2Ww8zAkEA5uuYtPD+\n' +
  '4wmFwy4BOrrl7yCMMem6uXFLLDlCDwP524gmAR5osoIIQQsHcr4JtC1FCkXqKJ56\n' +
  'YxKK4use8I1haw==';

// 加密
export function encrypt(txt) {
  // console.log(publicKey, '公钥');
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.decrypt(txt); // 对数据进行解密
}
