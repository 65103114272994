/**
 *  1、引入自己需要的加密方式
 *  2、接收一个数据，参数是请求体中的数据
 *  3、convertValuesToString()方法是对参数的转换，具体转换类型需要与后端商议！！！
 *  4、返回值为 sign="加密后的数据"  Data={nonce:"MD5密钥",timestamp:"当前时间戳"}
 * */

import { MD5 } from 'crypto-js'; //md5加密
import { encrypt } from './jsencrypt'; //RSA加密

export function md5AndRSA(data) {
  let Data = {};
  // todo随机字符串
  Data.nonce = Math.random()
    .toString(36)
    .slice(-8);

  //todo时间戳
  Data.timestamp = new Date().getTime();

  // todo把参数合并  为排序做准备
  Data = Object.assign(data, Data);

  // 把对象中所有的key拿到进行排序
  let keys = Object.keys(Data).sort();

  // 把排好序的key依次赋值
  var newObj = [];
  for (var i = 0; i < keys.length; i++) {
    //!把参数进行类型转换
    Data[keys[i]] = convertValuesToString(Data[keys[i]]);

    if (Data[keys[i]] instanceof Array) {
      // console.log(Data[keys[i]], '数组');
      if (Object.prototype.toString.call(Data[keys[i]][0]) === '[object Object]') {
        newObj.push(`${keys[i]}=${JSON.stringify(Data[keys[i]])}`);
      } else {
        newObj.push(`${keys[i]}=${JSON.stringify(Data[keys[i]])}`);
      }
    } else if (Object.prototype.toString.call(Data[keys[i]]) === '[object Object]') {
      // console.log(Data[keys[i]], '对象');
      if (JSON.stringify(Data[keys[i]]) === '{}') {
        newObj.push(`${keys[i]}=[]`);
      } else {
        newObj.push(`${keys[i]}=${JSON.stringify(Object.values(Data[keys[i]]))}`);
      }
    } else {
      newObj.push(`${keys[i]}=${Data[keys[i]]}`);
    }
  }
  console.log(newObj.join('&'), '未加密前的参数');

  //!MD5加密并转大写
  newObj = MD5(newObj.join('&'))
    .toString()
    .toUpperCase();

  //!RSA加密
  let sign = encrypt(newObj);

  return { sign, Data };
}

function convertValuesToString(obj) {
  // 如果obj是一个对象
  if (typeof obj === 'object' && obj !== null) {
    // 如果是数组
    if (Array.isArray(obj)) {
      // 遍历数组中的每个元素进行转换
      return obj.map(item => convertValuesToString(item));
    } else {
      const newObj = {};
      // 遍历对象的每个属性进行转换
      for (let key in obj) {
        if (obj[key] === null) {
          newObj[key] = '';
        } else if (typeof obj[key] === 'boolean') {
          if (obj[key] === true) {
            newObj[key] = '1';
          } else {
            newObj[key] = '0';
          }
        } else {
          newObj[key] = convertValuesToString(obj[key]);
        }
      }
      return newObj;
    }
  }
  if (obj === null) {
    obj = '';
  }
  // 如果是其他类型的值，则将其转换为字符串
  return obj;
}


export function  queryURLParams(url){
  var obj = {};
  var params = url.slice(url.indexOf('?') + 1);
  var arr = (params.split('&'));
  arr.forEach((v, i) => {/*  */
  let str=v.split('=')[1];
  if (!str) return {}
  if(str.indexOf('%')===-1){
      obj[v.split("=")[0]] =v.split("=")[1];
  }else{
      obj[v.split("=")[0]] =JSON.parse(decodeURIComponent(v.split("=")[1]));
  }
  
  })
  return obj;
}