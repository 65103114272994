<template>
  <div
    :class="{
      'header-main': true,
      isNumberpersontrademodule: isIndexModule === 2,
      isjiaoyidating: isIndexModule === 3,
      procenterSecondaryMenu: isIndexModule === 4,
    }"
    :style="{
      color: bgimgUrl ? '#000000' : '#FFFFFF',
      backgroundImage: bgimgUrl ? 'url(' + bgimgUrl + ')' : '',
    }"
  >
    <!-- 顶部菜单 :router="true"-->
    <el-menu
      open="/productcenter"
      :default-active="defaultActive"
      class="el-menu-map"
      mode="horizontal"
      @select="handleSelect"
    >
      <!-- logo -->
      <li style="height: 90px; line-height: 90px">
        <el-image
          style="width: 86px; height: 48px; margin-top: 21px; cursor: pointer"
          @click.native="$router.push('/')"
          :src="
            isIndexModule === 3 || isIndexModule === 4
              ? require('@/assets/images/index/changexinLogoBlack.png')
              : require('@/assets/images/index/changexin.png')
          "
          fit="fill"
        ></el-image>
      </li>
      <template v-for="item in routerList">
        <!-- 一级菜单 -->
        <el-menu-item
          v-if="!item.children && !item.hidden"
          :index="item.path"
          :key="item.name"
          @click="$router.push({ name: item.name })"
        >
          {{ item.moduleName }}
        </el-menu-item>

        <!-- 一级含二级菜单(一级及可点击又可展开) -->
        <el-menu-item
          v-if="item?.children?.length && !item.hidden"
          :index="item.path"
          :key="item.name"
          @click="$router.push({ name: item.name })"
        >
          <!-- onlyShowOneLevelMenu： 只显示一级不显示二级下拉 -->
          <div class="navFirstVal" v-if="item.onlyShowOneLevelMenu">
            {{ item.moduleName }}
          </div>
          <!-- 二级菜单 -->
          <el-submenu
            popper-class="artSubmenu"
            :popper-append-to-body="false"
            v-if="
              item?.children?.length &&
              !item.hidden &&
              !item.onlyShowOneLevelMenu
            "
            :index="item.path"
            :key="item.name"
          >
            <template slot="title">{{ item.moduleName }}</template>
            <template v-for="itemC in item.children">
              <el-menu-item
                :disabled="itemC.name === 'productcentervirtuallyApp'"
                :style="{ height: itemC.hidden ? 0 : '71px' }"
                :index="`${item.path}/${itemC.path}`"
                :key="itemC.name"
                @click="$router.push({ name: itemC.name })"
              >
                {{ !itemC.hidden ? itemC.moduleName : "" }}
              </el-menu-item>
            </template>
          </el-submenu>
        </el-menu-item>
      </template>
      <!-- 进入我的工作台 -->
      <li v-if="isIndexModule === 3" class="artTogongzuotai">
        <!-- <el-button >注册/登陆</el-button> -->
        <el-button
          class="gongzuotaiBtn"
          @click="toggleWork"
          type="primary"
          style="float: right"
          >进入我的工作台</el-button
        >
      </li>
      <li v-if="isIndexModule === 1" class="artLianxiwomen el-menu-item">
        联系我们
      </li>
    </el-menu>

    <!--  文案 start -->
    <div class="trade-header-main" v-if="currentModuleName === 'index'">
      <p class="item-title">助力虚拟数字核心资产</p>
      <p class="item-text">打造元宇宙数字经济新产业</p>
      <el-button class="item-btnw" type="primary"
        ><span>了解更多</span
        ><img :src="require('@/assets/images/index/首页箭头.png')" alt=""
      /></el-button>
    </div>
    <div class="shuziren" v-if="currentModuleName === 'numberperson'">
      <div class="item-title">
        <p class="title1">用AI数字人</p>
        <div style="width: 6.4vw"></div>
        <p class="title2">打开你的元宇宙世界</p>
      </div>
      <p class="item-btn" @click="jumpPlaform">使用创作工作台</p>
    </div>
    <div class="xuni" v-if="currentModuleName === 'virtuacontent'">
      <div class="item-title">
        <p class="title1">虚拟内容创作</p>
        <div style="width: 4.4vw"></div>
        <p class="title2">打造0距离社交</p>
      </div>
      <p class="item-btn" @click="jumpPlaform">使用创作工作台</p>
    </div>
    <div class="coreart" v-if="currentModuleName === 'coreart'">
      <div class="item-title">
        <p class="title1">全栈自研核心AI技术体系支持</p>
      </div>
      <p class="item-text2">
        Full stack self-developed core AI technology system support
      </p>
    </div>
    <div class="coreart" v-if="currentModuleName === 'ecologyoperate'">
      <div class="item-title">
        <p class="title1">虚拟人产业数字化解决方案</p>
      </div>
      <p class="item-text2">Digital Solution for Virtual Human industry</p>
    </div>
    <div class="shengch" v-if="currentModuleName === 'productcenterIndex'">
      <p>搭建一站式</p>
      <p>智慧工厂实施方案</p>
    </div>
    <div class="shengtai" v-if="currentModuleName === 'solution'">
      <div class="item-title">
        <p class="title1">产格新生态合作</p>
      </div>
      <p class="item-text2">Ecological cooperati</p>
    </div>
    <div
      class="cxuni"
      v-if="currentModuleName === 'productcentervirtuallyDigital'"
    >
      <div class="item-title">
        <p class="title1">虚拟数字人创作平台</p>
      </div>
      <p class="item-text2">Virtual digital character creation platform</p>
    </div>
    <div class="cxuni" v-if="currentModuleName === 'productcenterAi'">
      <div class="item-title">
        <p class="title1">AI虚拟能力平台</p>
      </div>
      <p class="item-text2">AI virtual capability platform</p>
    </div>
    <!--  文案 end -->
  </div>
</template>

<script>
export default {
  name: "headerMain",
  created() {
    // 切换导航菜单&菜单高亮
    this.getdefaultNavActive();

    // 获取indexBg 下的所有img文件名
    const files = require.context("@/assets/images/bg", false, /\.png$/);
    this.bgImgAllName = files
      .keys()
      .map((item) => item.split("/")[1].split(".")[0]);
  },
  watch: {
    $route: {
      handler(newValue) {
        this.currentModuleName = newValue.name;
        if (
          (newValue.name && newValue.name.indexOf("gongzuotai") !== -1) ||
          newValue.name.indexOf("Taskaddition") !== -1
        ) {
          this.$emit("showFooter", false);
        } else {
          this.$emit("showFooter", true);
        }
        // 切换导航菜单&菜单高亮
        this.getdefaultNavActive();
      },
      deep: true,
    },
  },
  methods: {
    // 切换导航菜单&菜单高亮
    getdefaultNavActive() {
      // 获取当前路由有点问题，先这样以后再看一下；----lx
      // console.log('152', this.$route);  // ???
      const currentRouter = location.hash.split("#")[1].split("?")[0]; // 多级 动态参数时判断有问题

      // 菜单高亮
      this.defaultActive =
        currentRouter.indexOf("numberpersontrademodule") === -1
          ? currentRouter
          : currentRouter.split("/")[2].split(":")[0].split("/")[0];
      console.log("bg174", currentRouter, this.defaultActive);

      // 数字人首页
      if (currentRouter?.indexOf("numberpersontrademodule") !== -1) {
        this.isIndexModule = 2;
        this.routerList = this.$router.options.routes.filter(
          (item) => item.name === "numberpersontrademodule"
        )[0].children;

        // bgimg
        this.bgimgUrl = this.CheckImgExists(this.defaultActive);

        // 数字人交易大厅
        // 数字人任务大厅
        if (
          this.defaultActive === "tradingfloor" ||
          this.defaultActive === "taskhall" ||
          currentRouter.split("/")[3] === "search"
        ) {
          this.isIndexModule = 3;
          this.bgimgUrl = "";
        }
      } else {
        // 首页
        this.isIndexModule = 1;
        this.routerList = this.$router.options.routes;

        // bgimg
        this.bgimgUrl = this.CheckImgExists(this.defaultActive.split("/")[1]);
        // 产品中心二级菜单
        if (
          this.productcenterChildrenNav.some(
            (item) => this.defaultActive.indexOf(item) !== -1
          )
        ) {
          this.isIndexModule = 4;
          // bgimg
          this.bgimgUrl = this.CheckImgExists(this.defaultActive.split("/")[2]);
        }
      }
    },
    // menu点击
    handleSelect(key, keyPath) {
      console.log(192, key, keyPath);
      if (["tradesindex", "ipcooperation"]?.indexOf(key) !== -1) {
        // 数字人首页 IP合作
        this.isIndexModule = 2;
      } else if (
        ["tradingfloor", "tradesindexSearch", "taskhall"]?.indexOf(key) !== -1
      ) {
        // 数字人交易大厅
        this.isIndexModule = 3;
      } else if (
        this.productcenterChildrenNav.some((item) => key.indexOf(item) !== -1)
      ) {
        // 产品中心
        this.isIndexModule = 4;
      } else {
        // 首页
        this.isIndexModule = 1;
      }
    },
    toggleWork() {
      // this.$router.push({ name: 'gongzuotaiHome'})
      this.$router.push({ name: "gongzuotaiIndex" });
    },
    // 判断图片是否存在
    CheckImgExists(imgName) {
      if (this.bgImgAllName.indexOf(imgName) !== -1) {
        return require("@/assets/images/bg/" + imgName + ".png");
      } else {
        return "";
      }
    },
    jumpPlaform() {
      window.open(`${process.env.VUE_APP_WORK_PATH}/#/login`);
    },
  },
  data() {
    return {
      defaultActive: "/",
      currentModuleName: "",
      bgimgUrl: "",
      isIndexModule: 1, // 首页： 1， 交易人首页： 2， 交易人大厅：3， 产中心2及菜单： 4，
      productcenterChildrenNav: [
        "productcenterAi",
        "productcentervirtuallyDigital",
      ],
      bgImgAllName: [],
    };
  },
};
</script>
<style lang="less" scoped>
.navActive(@background) {
  position: absolute;
  content: "";
  top: 110%;
  transform: translateY(-72%);
  left: 50%;
  margin-left: -1.04vw;
  height: 2px;
  width: 2.08vw;
  // background: linear-gradient(90deg,
  //     #FFFFFF 0%,
  //     #005DFF 47%,
  //     #FFFFFF 100%);
  background: @background;
}
.header-main {
  width: 100vw;
  height: 41.67vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;

  // 数字人交易
  &.isNumberpersontrademodule {
    height: 32.34vw;
    min-height: 300px;
    background-image: none;
    background-repeat: no-repeat;
  }

  // 数字人交易-交易大厅
  &.isjiaoyidating,
  &.procenterSecondaryMenu {
    height: 90px;
    background: #ffffff;
    box-shadow: 0px 2px 7px 4px rgba(0, 0, 0, 0.06);

    &.header-main .el-menu--horizontal > .el-menu-item {
      color: #000000 !important;

      &.is-active {
        font-weight: bold;
        border-bottom: none;
      }

      &.is-active:after {
        .navActive(linear-gradient(90deg, #FFFFFF 0%, #005dff 47%, #FFFFFF 100%));
      }
    }
  }

  // 产品中心二级菜单
  &.procenterSecondaryMenu {
    height: 32.34vw;
    min-height: 300px;
    background-size: cover;
    background-position: 0 90px;
    background-repeat: no-repeat;
    /deep/ .el-menu--horizontal {
      & > .el-menu-item .el-submenu .el-submenu__title {
        color: #000000 !important;
      }
      .el-menu-item .el-submenu {
        &.is-active {
          font-weight: bold;
          border-bottom: none;
        }

        &.is-active:after {
          .navActive(linear-gradient(90deg, #FFFFFF 0%, #005dff 47%, #FFFFFF 100%));
        }
      }
    }
  }

  // 菜单 start
  /deep/ .el-menu--horizontal {
    & > .el-submenu .el-submenu__title:hover {
      background-color: transparent;
    }

    &.el-menu {
      border-bottom: none;
    }

    // 一级菜单
    & > .el-menu-item {
      padding: 0;
      color: #000000;

      .navFirstVal {
        width: 90px;
        text-align: center;
      }

      .el-submenu,
      .el-submenu__title {
        width: 100%;
        height: 60px;
        line-height: 90px;
        font-size: 1rem;
        padding: 0 0px;
        color: #000000;
      }
      .el-submenu.is-active .el-submenu__title {
        border-bottom: 2px solid #409eff;
      }

      .el-submenu__title:hover {
        background-color: transparent;
      }

      .el-submenu__icon-arrow {
        display: none;
      }

      // 一级菜单 end

      // 二级菜单
      .el-submenu {
        .el-submenu__title {
          color: #ffffff !important;
          line-height: 90px;
        }

        &.is-active .el-submenu__title {
          border-bottom: none !important;
        }

        &.is-active {
          font-weight: bold;
          border-bottom: none;
        }

        &.is-active:after {
          .navActive(linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 47%, rgba(255, 255, 255, 0) 100%));
        }

        // 弹出的二级菜单
        .artSubmenu {
          left: -72px !important;
          color: red;

          // display: block !important;
          .el-menu--popup {
            padding: 0 0;
            overflow: hidden;
          }
          .el-menu.el-menu--popup.el-menu--popup-bottom-start {
            width: 232px;
            height: 213px;
            border-radius: 8px;

            .el-menu-item {
              text-align: center;
              height: 71px;
              line-height: 71px;
              font-size: 16px;
              font-family: MicrosoftYaHei;
              color: #666666;

              &.is-active {
                // background: #F5F8FB;
                background: #c7c9c9;
                font-size: 16px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
              }
            }
          }
        }
      }
    }
    .artTogongzuotai {
      height: 90px;
      line-height: 90px;
      flex: 1;
      display: flex;
      justify-content: end;
      align-items: center;
      .gongzuotaiBtn {
        width: 169px;
        height: 48px;
        background: #005dff;
        border-radius: 6px;
        color: #ffffff;
      }
    }
    // .artLianxiwomen {
    //   flex: 1;
    //   justify-content: end;
    //   font-size: 1rem;
    //   font-family: MicrosoftYaHei;
    //   color: #ffffff;
    //   height: 90px;
    //   line-height: 90px;
    //   text-align: right;
    // }
  }

  // 菜单
  .el-menu--horizontal {
    min-width: 72.92vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 90px;
    margin: 0 auto;
    display: flex;
    background-color: transparent !important;

    &.el-menu {
      padding-bottom: 0px;
    }

    & > .el-menu-item {
      border-bottom: none;
      // margin: 0 20px;
      color: #ffffff !important;
      font-size: 1rem;
      float: left;
      line-height: 90px;
      white-space: nowrap;
      color: #fff;
      margin-left: 2.86vw;
      cursor: pointer;
      position: relative;

      &.is-active {
        font-weight: bold;
        border-bottom: none;
      }

      &.is-active:after {
        .navActive(linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 47%, rgba(255, 255, 255, 0) 100%));
      }
    }

    & > .el-submenu {
      .el-submenu__title {
        color: #ffffff !important;
      }
    }

    /deep/ .el-menu-item:focus,
    .el-menu-item:hover {
      outline: 0;
      background-color: transparent !important;
    }
  }
  // 菜单 end

  // 背景文案 start
  .trade-header-main {
    width: 72.92vw;
    margin: 0 auto;

    .item-title {
      font-size: 3.33rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      // line-height: 58px;
      letter-spacing: 4px;
    }

    .item-text {
      font-size: 1.33rem;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 22px;
      letter-spacing: 1px;
      margin-top: 30px;
    }

    // .item-btn {
    //   width: 10.42vw;
    //   height: 60px;
    //   cursor: pointer;
    //   text-align: center;
    //   line-height: 60px;
    //   margin-top: 60px;
    //   font-size: 1rem;
    //   font-family: PingFangSC-Regular, PingFang SC;
    //   font-weight: 400;
    //   color: #ffffff;
    //   background: #005ff8;
    // }
    .el-button--primary {
      width: 10.42vw;
      height: 60px;
      margin-top: 50px;
      background: #005ff8;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 0;
      border: none;
      p {
        display: inline-block;
        width: 5vw;
        height: 60px;
        font-size: 1rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      img {
        display: inline-block;
        width: 1vw;
        margin-left: 1vw;
      }
    }
  }

  .ceng1 {
    display: flex;
    width: 100vw;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item-title-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 0 auto;
  }

  .p-1 {
    font-size: 4.375rem;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 92px;
  }

  .item-btn-1 {
    margin-top: 6.88vw;
    width: 25vw;
    height: 75px;
    border-radius: 0.625rem;
    font-size: 1.875rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 70px;
    color: #ffffff;
    text-align: center;
  }
  .item-text-2 {
    width: 136.533vw;
    height: 30px;
    margin-top: 2vw;
    font-size: 1.5rem;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 22px;
    letter-spacing: 5px;
    text-align: center;
  }

  .shuziren {
    .ceng1();

    margin-top: 6.09vw;
    .item-title {
      .item-title-1();
      p {
        .p-1();
      }
    }

    .item-btn {
      cursor: pointer;
      .item-btn-1();
      box-shadow: 0px 0px 12px 1px #ffffff;
      border: 2px solid #ffffff;
    }
  }

  .xuni {
    .ceng1();
    margin-top: 6.09vw;
    .item-title {
      .item-title-1();
      p {
        .p-1();
      }
    }
    .item-btn {
      cursor: pointer;
      .item-btn-1();
      background-color: #0063fa;
      box-shadow: 0px 0px 12px 1px #0063fa;
      border: 2px solid #0063fa;
    }
  }

  .coreart {
    .ceng1();
    .item-title {
      .item-title-1();
      p {
        .p-1();
      }
    }
    .item-text2 {
      .item-text-2();
    }
  }

  .shengtai {
    .ceng1();
    .item-title {
      .item-title-1();
      p {
        .p-1();
        line-height: 112px;
        letter-spacing: 27px;
      }
    }
    .item-text2 {
      .item-text-2();
    }
  }

  .cxuni {
    .ceng1();
    .item-title {
      .item-title-1();
      p {
        .p-1();
        line-height: 92px;
        letter-spacing: 23px;
      }
    }
    .item-text2 {
      .item-text-2();
    }
  }

  .shengch {
    .ceng1();
    p {
      font-size: 4.375rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 92px;
      letter-spacing: 27px;
    }
  }

  // 背景文案 end
}
</style>
