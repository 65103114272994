<template>
    <div class="upload-file">
        <!-- 文件列表 -->
        <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
            <li :key="index + file.uid" class="el-upload-list__item ele-upload-list__item-content"
                v-for="(file, index) in fileList">
                <div class="cover">
                    <img :src="file.url" />
                </div>
                <span class="el-upload-list__item-actions">
                    <i class="el-icon-delete"  @click="handleDelete(index)"></i>
                </span>
                <!-- <div class="ele-upload-list__item-content-action">
                    <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
                </div> -->
            </li>
        </transition-group>
        <!-- :action="uploadFileUrl" 
         :on-success="handleUploadSuccess"
        :on-error="handleUploadError" -->
        <el-upload
            :disabled="disabled"
            action=""
            :http-request="uploadFile"
            :before-upload="handleBeforeUpload"
            :file-list="fileList"
            :show-file-list="false"
            :headers="headers"
            :class="{ 'upload-demo': true, 'avatar-uploader': true }"
            list-type="picture-card"
            ref="files"
        >
            <!-- 上传按钮 -->
            <i class="el-icon-plus">
                <div class="el-upload__text">{{ inTip }}</div>
            </i>
            <!-- <el-button size="mini" type="primary" :disabled="disabled">选取文件</el-button> -->
            <!-- 上传提示 -->
            <!-- <div class="el-upload__tip" slot="tip" v-if="showTip">
                请上传
                <template v-if="fileSize">
                    大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
                </template>
                <template v-if="fileType">
                    格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
                </template>
                的文件
            </div> -->
        </el-upload>

        
    </div>
</template>

<script>
// import { getToken } from '@/utils/auth'
import { cosUpload } from '@/utils/cosUtils';
export default {
    name: 'FileUpload',
    props: {
        // 值
        value: [String, Object, Array],
        // 数量限制
        limit: {
            type: Number,
            default: 1,
        },
        // 大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ['doc', 'xls', 'ppt', 'txt', 'pdf', 'webp', 'png', 'gif', 'jpg', 'mp3', 'mp4'],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: true,
        },
        inTip: {
            type: String,
            default: "",
        },
        disabled: Boolean,
    },
    data() {
        return {
            baseUrl: 'localhost',
            uploadFileUrl: process.env.VUE_APP_BASE_URL + '/api/ajax/upload', // 上传的图片服务器地址
            headers: {
                // token: getToken(),
            },
            fileList: [],
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    const temp = 1
                    // 首先将值转为数组
                    const list = Array.isArray(val) ? val : this.value.split(',')
                    // 然后将数组转为对象数组
                    this.fileList = list.map(item => {
                        if (typeof item === 'string') {
                            item = {
                                name: item,
                                url: item,
                                uid: Number(Math.random().toString().substr(2, 10) + Date.now()).toString(36), // new Date().getTime(), // + temp++
                            }
                        }

                        return item
                    })
                } else {
                    this.fileList = []
                    return []
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize)
        },
    },
    methods: {
        // 上传前校检格式和大小
        handleBeforeUpload(file) {
            // 校检文件类型
            if (this.fileType) {
                let fileExtension = ''
                if (file.name.lastIndexOf('.') > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
                }
                const isTypeOk = this.fileType.some(type => {
                    if (file.type.indexOf(type) > -1) return true
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true
                    return false
                })
                if (!isTypeOk) {
                    this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}格式文件!`)
                    return false
                }
            }
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
                    return false
                }
            }
            return true
        },
        // 上传失败
        handleUploadError(err) {
            this.$message.error('上传失败, 请重试')
        },
         // 上传成功
        async uploadFile(item) {
            // 上传一张图片
            const fileUrl = await cosUpload(item.file);
            this.fileList.push({
                name: fileUrl,
                url: fileUrl,
                uid: new Date().getTime(),
            })
            this.$emit('uploadInput', this.listToString(this.fileList))
            //返回 v-model的参数（关键）
            this.$emit('input', this.listToString(this.fileList))
        },
        // 上传成功回调
        handleUploadSuccess(res, file) {
            if (res.code != 0) {
                this.$message.success('上传成功');
                console.log(res.data.url, '163', this.fileList)
                this.fileList.push({
                    name: res.data.url,
                    url: res.data.url,
                    uid: Number(Math.random().toString().substr(2, 10) + Date.now()).toString(36), // new Date().getTime(),
                })
                this.$emit('uploadInput', this.listToString(this.fileList))
                //返回 v-model的参数（关键）
                this.$emit('input', this.listToString(this.fileList))
            } else {
                this.fileList = []
                this.$message({
                    type: 'error',
                    message: res.msg,
                })
            }
        },
        // 删除文件
        handleDelete(index) {
            this.fileList.splice(index, 1)
            console.log(index, this.fileList);
            this.$emit('input', this.listToString(this.fileList))
        },
        // 获取文件名称
        getFileName(name) {
            if (name.lastIndexOf('/') > -1) {
                return name.slice(name.lastIndexOf('/') + 1).toLowerCase()
            } else {
                return ''
            }
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = ''
            separator = separator || ','
            for (const i in list) {
                strs += list[i].url + separator
            }

            console.log(strs, '197');
            return strs != '' ? strs.substr(0, strs.length - 1) : ''
        },
    },
}
</script>

<style scoped lang="less">
.upload-file-uploader {
    margin-bottom: 5px;
}



.ele-upload-list__item-content-action .el-link {
    margin-right: 10px;
}

.upload-file {
    display: flex;
    flex-wrap: wrap;

    /deep/ .upload-demo {
        float: right;
        position: relative;
        overflow: hidden;
        width: 200px;
        height: 200px;

        .el-upload--picture-card {
            float: left;
            width: 200px;
            height: 200px;
            background: #1A6FF9;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;

            .el-icon-plus {
                &:before {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    font-size: 60px;
                    content: "\E6D9";
                    color: #FFFFFF;
                }

                .el-upload__text {
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 19px;
                }
            }
        }

        .el-upload-list--picture-card {
            height: 200px;

            
        }

        .el-upload-list {
            float: left;
        }
    }

    .upload-file-list {
        display: flex;
        flex-wrap: wrap;
        .el-upload-list__item {
            margin-top: 0px;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 200px;
            height: 200px;
            border: 1px solid #e4e7ed;
            line-height: 2;
            position: relative;
            .cover {
                margin: 0 auto;
                width: 200px;
                height: 200px;
                img {
                    width: 200px;
                    height: 200px;
                }
            }

            &:first-child {
                margin-top: 0px;
            }

            .el-upload-list__item-actions {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                margin-left: -15px;
                .el-icon-delete {
                    display: none;
                    font-size: 30px;
                    color: #1A6FF9;
                }
            }

            &:hover .el-icon-delete {
                display: block;
            }
        }

        .ele-upload-list__item-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: inherit;
        }
    }
}
</style>
