<template>
  <div class="blog-post">
    <div>
        <p class="blog-post-title">
            <img v-if="leftIcon" class="leftImg" :src="require('@/assets/images/index/shape_triangle.230.png')" alt=""/>
            {{title}}
            <img v-if="rightIcon" class="rightImg" :src="require('@/assets/images/index/shape_triangle.230(1).png')" alt=""/>
        </p>
        <p class="blog-post-content">
            {{ content }}

        </p>
    </div>

  </div>
</template>

<script>

export default {
name:'blogpost',
props:{ 
    title: {
        type:String,
        default:""
    }, 
    content: {
        type:String,
        default:""
    },
    leftIcon: {
        type: Boolean,
        default: false
    },
    rightIcon: {
        type: Boolean,
        default: false
    },
},
data(){
    return {
        
    }
}
}

</script>
<style  lang="less" scoped>
.blog-post {
    margin-top: 60px;
    width: 100vw;
    text-align: center;
    div {
         width: 100vw;
         color: #fff;
        .blog-post-title {
            font-size: 2rem;
             margin-bottom: 10px;;
             font-weight: bold;
             font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
             height: 2.6vw;
             line-height: 2.6vw;
             display: flex;
             align-content: center;
             justify-content: center;

             .leftImg {
                margin-right: 0.73vw;
                width: 2.6vw;
                height: 2.6vw;
             }
             .rightImg {
                margin-left: 0.73vw;
                width: 2.6vw;
                height: 2.6vw;
             }
        }
        .blog-post-content {
            margin: 0 auto;
            width: 686px;
            line-height: 26px;
            font-size: 15px;  
            color: #CECECE;
            text-align: center;
        }
    }
}
</style>