
// order 订单常量
export const orderConstant = {
    order_status_obj: {
        1: '待付款',
        2: '已完成',
        3: '已关闭',
    },
    settle_way_obj: {
        1: '直接支付',
        2: '保底授权+固定分成'
    },
    typeObj: {
        1: '2D角色',
        2: '3D角色',
        3: '声音克隆',
        4: '数字场景生成',
        5: 'API应用',
        6: '需求任务',
    },
    empower_way_obj: {
        1: '买断制',
        2: '许可',
    },
    check_status_obj: {
        1: '未交付',
        2: '已交付',
        3: '已验收',
    }
}

export default {
    ...orderConstant,
}