/**
 * 校验service
 * @return {Promise} - 返回 resolved 或者 rejected 状态的 Promise 对象
 */
let Global = window;
let _validator = {};

_validator._validations = {
    PLATE_NUMBER: {
        rule: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4}))$)|(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$)/
    },
    ID_NUMBER: {
        rule: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
    },
    CELLPHONE_NUMBER:{
        rule:/^1\d{10}$/
    },
    INT_NUMBER :{
        rule:/^\+?[1-9][0-9]*$/
    },
	POSITIVE_INTEGER :{
		rule:/^[1-9]{1}[0]{0,1}$/
    },
    SPECIAL_CHARTS: {
        rule:"[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】《》‘；：”“'。，、？]"
    }
};

_validator.TYPES = {
	PLATE_NUMBER: "PLATE_NUMBER",
	ID_NUMBER: "ID_NUMBER",
	INT_NUMBER: "INT_NUMBER",
    POSITIVE_INTEGER: "POSITIVE_INTEGER",
    SPECIAL_CHARTS:"SPECIAL_CHARTS"
};
/**
 * 添加校验类型，增加类型验证，防止由于覆盖已存在的校验规则引起bug
 * @params {string} - type,
 * @params {RegExp|Function|Promise} - rule
 */
_validator._addValidation = function(type,rule) {
    if(_validator._validations.hasOwnProperty(type)) {
        throw new Error("无法添加已经存在的校验类型，请修改校验类型名称")
    }
    _validator._validations[type] = {
        rule
    };
    _validator.TYPES[type] = type;
}

/**
 * 异步校验方法
 * @params {string} type - 校验类型，
 * @params {any} str - 校验目标
 * @return {Promise} - 校验是否通过
 */
_validator.checkWithPromise = function(type, str) {
    if (!_validator._validations[type]) {
        throw new Error("未知的校验类型");
    }
    let rule = _validator._validations[type].rule;
    let regExp = rule instanceof RegExp ? rule : new RegExp(rule);
    if (regExp.test(str)) {
        return Promise.resolve(str);
    }
    if (typeof rule == "function") {
        if (rule(str).then) {
            return rule(str);
        }
        if (rule(str)) {
            return Promise.resolve(str);
        }
    }
    return Promise.reject(str);
};
/**
 * 同步校验方法
 * @params {string} type - 校验类型，
 * @params {any} str - 校验目标
 * @return {boolean} - 校验是否通过
 */
_validator.check = function(type,str) {
    if (!_validator._validations[type]) {
        throw new Error("未知的校验类型");
    }
    let rule = _validator._validations[type].rule;
    let regExp = rule instanceof RegExp ? rule : new RegExp(rule);
    if (regExp.test(str)) {
       return true
    }
    if (typeof rule == "function") {
        if (rule(str).then) {
            throw new Error("不支持此类型校验，请用 $validator.checkWithPromise 方法")
        }
        return rule(str)
    }
    return false
}
/**
 * 装载校验规则
 * @params 一个参数类型为 Arrry<{type:string,rule:string|RegExp}>,
 *         两个参数类型为 type:string,rule:string|RegExp,
 *         三个参数类型为与一个参数类似，后两个参数为 type 和 rule 的 key 值
 * @example 
 * $validator.install([type:'',rule:/\g/])
 * $validator.install(type,rule)
 * $validator.install([type:'',rule:/\g/],'key','value')
 */
_validator.install = function(...args) {
    if (args.length == 0) {
        return;
    }
    if (args.length == 1 && Array.isArray(args[0])) {
        args[0].forEach(validate => {
            let type = validate.type,
                rule = validate.rule;
            _validator._addValidation(type,rule)
        });
    }
    if (args.length == 2) {
        let type = args[0],
            rule = args[1];
        _validator._addValidation(type,rule)
    }
    if (args.length == 3 && Array.isArray(args[0])) {
        args[0].forEach(validate => {
            let type = validate[args[1]],
                rule = validate[args[2]];
            _validator._addValidation(type,rule)
        });
    }
};

Global.$validator = {};

Object.defineProperties(Global.$validator, {
    check: {
        value: _validator.check,
        writable: false
    },
    checkWithPromise: {
        value: _validator.checkWithPromise,
        writable: false
    },
    install: {
        value: _validator.install,
        writable: false
    },
    TYPES: {
        value: _validator.TYPES
    }
});

export default Global.$validator;
