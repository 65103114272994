import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import useComponent from './utils/useComponent'
import directive from '@/utils/directive/directive'
import * as globalMethod from '@/utils/quickadmin';
import mynpmchat from 'mynpmchatonline/myNpmChat'
import "../node_modules/mynpmchatonline/myNpmChat/myNpmChat.css"
Vue.use(mynpmchat);
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import constantObj from '@/utils/constant';

Vue.use(useComponent);
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.use(directive)
Vue.use(mynpmchat)

Object.keys(globalMethod).forEach(key => {
  Vue.prototype[key] = globalMethod[key];
});



//路由重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$routes =  router
Vue.prototype.$constantObj = constantObj;
Vue.prototype.$bus =  new Vue();
Vue.config.productionTip = false
import './assets/css/reset.css'
import './services/index'
new Vue({
  render: h => h(App),
  router:router,

}).$mount('#app')
