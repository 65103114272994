<template>
  <div id="app">
    <headerMain @showFooter="showFooter" v-show="isShowFooter"></headerMain>
    <router-view></router-view>
    <div v-show="isShowFooter">
      <!-- <contactus></contactus>  -->
      <plaformFooter ></plaformFooter>
    </div>
   
  </div>
</template>

<script>
import headerMain from './modules/headerMain.vue'
import plaformFooter from './components/plaformFooter.vue'
import contactus from './components/contactus.vue'

export default {
  name: 'App',
  data(){
    return {
      isShowFooter: false,
      screenWidth:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
        lastTime:'',
        componentId:''
    }
  },
  created() {
    this.lastTime = new Date().getTime();
    this.fun();


    const currentRouter = location.hash.split('#')[1];
    //工作台模块isShowFooter = false; 后期记得数字人模块， App.vue 只保留router-view   --- lx
    if (currentRouter.indexOf('gongzuotai') === -1) {
      this.isShowFooter = false;
    } else {
      this.isShowFooter = true;
    }
    console.log('currentRouter', currentRouter)
    
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        (window.screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth),
          (that.screenWidth = window.screenWidth);
        this.fun();
      })();
    };
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    // 设置html标签的字体大小自适应   为了使得rem单位自适应
    fun() {
      const that = this;
      var htmlobj = document.getElementsByTagName("html")[0];
      htmlobj.style.fontSize = (that.screenWidth / 1920) * 18 + "px";
    },
    showFooter(val) {
      this.isShowFooter = val
    }
  },
  components: {
    headerMain,plaformFooter,contactus
  }
}
</script>

<style lang="less">

   #app {
    overflow: hidden;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
   }
</style>
