/**
 * 全站路由配置
 *
 * 强烈建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import VueRouter from "vue-router";
const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    //配置路由的路径
    {
      path: "/", // 路由地址
      redirect: "/index", // 重定向
      /** 是否在菜单中隐藏 */
      hidden: true,
    },
    {
      path: "/gongzuotaiHome",
      component: () => import("@/modules/gongzuotai/home.vue"),
      name: "gongzuotaiHome",
      moduleName: "工作台home",
      hidden: true,
    },
    {
      path: "/gongzuotai",
      name: "gongzuotai",
      moduleName: "工作台",
      redirect: "/gongzuotai/index",
      /** 是否在菜单中隐藏 */
      hidden: true,
      component: () => import("@/modules/gongzuotai/App.vue"),
      children: [
        {
          path: "index",
          component: () => import("@/modules/gongzuotai/modules/index.vue"),
          name: "gongzuotaiIndex",
          moduleName: "工作台首页",
        },
        {
          path: "/gongzuotaiIndex/detail",
          name: "gongzuotaiIndex/detail",
          component: () => import("../modules/gongzuotai/detail.vue"),
        },
        {
          path: "/gongzuotaiIndex/audioDetail",
          name: "gongzuotaiIndex/audioDetail",
          component: () =>
            import("../modules/gongzuotai/modules/IndexDetailAudio.vue"),
        },
        {
          path: "transactionManage",
          component: () =>
            import("@/modules/gongzuotai/modules/transactionManage.vue"),
          name: "gongzuotaiTransactionManage",
          moduleName: "交易管理",
        },
        {
          path: "digitalIPManage",
          component: () =>
            import("@/modules/gongzuotai/modules/digitalIPManage.vue"),
          name: "gongzuotaiDigitalIPManage",
          moduleName: "数字IP管理",
        },
        {
          path: "copyrightContract",
          component: () =>
            import("@/modules/gongzuotai/modules/copyrightContract.vue"),
          name: "gongzuotaiCopyrightContract",
          moduleName: "版权合同",
        },
        {
          path: "myCollection",
          component: () =>
            import("@/modules/gongzuotai/modules/myCollection.vue"),
          name: "gongzuotaiMyCollection",
          moduleName: "我的收藏",
        },
        {
          path: "messageCenter",
          component: () =>
            import("@/modules/gongzuotai/modules/messageCenter.vue"),
          name: "gongzuotaiMessageCenter",
          moduleName: "消息中心",
        },
        {
          path: "personalInformation",
          component: () =>
            import("@/modules/gongzuotai/modules/personalInformation.vue"),
          name: "gongzuotaiPersonalInformation",
          moduleName: "个人信息",
        },
        {
          path: "taskCenter",
          component: () =>
            import("@/modules/gongzuotai/modules/taskCenter.vue"),
          name: "gongzuotaiTaskCenter",
          moduleName: "我发布的任务",
        },
        {
          path: "taskCenterDetail",
          component: () =>
            import("@/modules/gongzuotai/modules/taskCenterDetail.vue"),
          name: "gongzuotaiTaskCenterDetail",
          moduleName: "任务详情",
        },
      ],
    },
    {
      path: "/index",
      component: () => import("@/modules/index/index.vue"),
      name: "index",
      moduleName: "首页",
      moduleBg: require("@/assets/images/bg/index.png"),
    },
    {
      path: "/numberperson",
      component: () => import("@/modules/numberperson/index.vue"),
      name: "numberperson",
      moduleName: "数字人",
      moduleBg: require("@/assets/images/bg/numberperson.png"),
    },
    {
      path: "/virtuacontent",
      component: () => import("@/modules/virtuacontent/index.vue"),
      name: "virtuacontent",
      moduleName: "虚拟内容",
      moduleBg: require("@/assets/images/bg/virtuacontent.png"),
    },
    {
      path: "/coreart",
      component: () => import("@/modules/coreart/index.vue"),
      name: "coreart",
      moduleName: "核心技术",
      moduleBg: require("@/assets/images/bg/coreart.png"),
    },
    {
      path: "/ecologyoperate",
      component: () => import("@/modules/ecologyoperate/index.vue"),
      name: "ecologyoperate",
      moduleName: "解决方案",
      moduleBg: require("@/assets/images/bg/ecologyoperate.png"),
    },
    {
      path: "/productcenter",
      component: () => import("@/modules/productcenter/index.vue"),
      name: "/productcenter",
      moduleName: "产品中心",
      moduleBg: require("@/assets/images/bg/productcenterIndex.png"),
      redirect: "/productcenter/productcenterIndex",
      children: [
        {
          path: "productcenterIndex",
          component: () =>
            import("@/modules/productcenter/modules/productIndex.vue"),
          name: "productcenterIndex",
          moduleName: "产品中心首页",
          hidden: true,
        },
        {
          path: "productcentervirtuallyDigital",
          component: () =>
            import("@/modules/productcenter/modules/virtuallyDigital.vue"),
          name: "productcentervirtuallyDigital",
          moduleName: "虚拟数字人创作平台",
          moduleBg: require("@/assets/images/bg/productcentervirtuallyDigital.png"),
        },
        {
          path: "productcenterAi",
          component: () =>
            import("@/modules/productcenter/modules/aiVirtually.vue"),
          name: "productcenterAi",
          moduleName: "AI虚拟能力平台",
          moduleBg: require("@/assets/images/bg/productcenterAi.png"),
        },
        {
          path: "productcentervirtuallyApp",
          name: "productcentervirtuallyApp",
          moduleName: "线下虚拟实时APP",
        },
      ],
    },
    {
      path: "/solution",
      component: () => import("@/modules/solution/index.vue"),
      name: "solution",
      moduleName: "生态合作",
      // 没有图片
      moduleBg: require("@/assets/images/bg/solution.png"),
    },
    {
      path: "/numberpersontrademodule",
      name: "numberpersontrademodule",
      moduleName: "数字人交易",
      onlyShowOneLevelMenu: "true",
      component: () => import("@/modules/numberpersontrade/home.vue"),
      redirect: "/numberpersontrademodule/tradesindex", // 重定向
      children: [
        {
          path: "tradesindex",
          moduleName: "首页",
          name: "numberpersontrademodule/tradesindex",
          moduleBg: require("@/assets/images/bg/shuzirenIndex.png"),
          component: () =>
            import("@/modules/numberpersontrade/modules/index.vue"),
        },
        {
          path: "tradesindex/search",
          moduleName: "首页搜索",
          name: "numberpersontrademodule/tradesindex/search",
          component: () =>
            import("@/modules/numberpersontrade/modules/indexSearch.vue"),
          hidden: true,
        },
        {
          path: "tradesindex/search/detail",
          moduleName: "首页搜索详情",
          name: "numberpersontrademodule/tradesindex/searchDetail",
          component: () =>
            import(
              "@/modules/numberpersontrade/modules/tradingfloorDetail.vue"
            ),
          hidden: true,
        },
        {
          path: "tradingfloor",
          moduleName: "交易大厅",
          name: "numberpersontrademodule/tradingfloor",
          component: () =>
            import("@/modules/numberpersontrade/modules/tradingfloor.vue"),
        },
        {
          path: "tradingfloor/Detail",
          moduleName: "交易大厅详情",
          name: "numberpersontrademodule/tradingfloorDetail",
          component: () =>
            import(
              "@/modules/numberpersontrade/modules/tradingfloorDetail.vue"
            ),
          hidden: true,
        },
        {
          path: "taskhall",
          moduleName: "任务大厅",
          name: "numberpersontrademodule/taskhall",
          component: () =>
            import("@/modules/numberpersontrade/modules/taskhall.vue"),
        },
        {
          path: "taskhall/taskhallDetail",
          moduleName: "任务详情",
          name: "numberperson/taskhallDetail",
          component: () =>
            import("@/modules/numberpersontrade/modules/taskhallDetail.vue"),
          hidden: true,
        },
        {
          path: "ipcooperation",
          moduleName: "IP合作",
          name: "numberpersontrademodule/ipcooperation",
          moduleBg: require("@/assets/images/bg/ipcooperation.png"),
          component: () =>
            import("@/modules/numberpersontrade/modules/ipcooperation.vue"),
        },
      ],
    },
    // 投标
    {
      path: "/tender",
      moduleName: "投标",
      name: "Tender",
      component: () => import("@/modules/tall/tender.vue"),
      hidden: true,
    },
    // 任务新增
    {
      path: "/taskaddition",
      moduleName: "任务新增",
      name: "Taskaddition",
      component: () => import("@/modules/tall/taskaddition.vue"),
      hidden: true,
    },
  ],
});
export default router;
