import Vue from 'vue'
import blogPost from '@/components/blogpost.vue'
import imgList from '@/components/imgList.vue'
import changeTab from '@/components/changeTab.vue'
import fileUpload from '@/components/FileUpload/index.vue';
import Editor from '@/components/Editor/index.vue';
import yuanPage from '@/components/Lpagination.vue';
// 当我们需要进行多个组件的注册时候, 假如说都写到main.js里面的话, 
// 这样就会造成main.js文件的臃肿与杂乱
export default {
  install(Vue) {
    Vue.component('blogpost', blogPost);
    Vue.component('imgList', imgList);
    Vue.component('changeTab', changeTab);
    Vue.component('fileUpload', fileUpload);
    Vue.component('yuanPage', yuanPage);
    Vue.component('Editor', Editor);
  }
}