<template>
    <div class="bottom-page-div">
        <p>
            共
            <span>{{ count }}</span>
            条记录
        </p>
        <el-pagination class="el-pagin" ref="pagination" @current-change="handleCurrentChange"
            :current-page.sync="currentPage3" :page-size="pageSize" layout="prev, pager, next, jumper" :total="count">
        </el-pagination>
        <el-button type="button" @click="jumpLastPage" plain>尾页</el-button>
    </div>
</template>
<script>
export default {
    name: "yuanPage",
    data() {
        return {

        }
    },
    props: {
        count: {
            type: Number,
            required: true,
        },
        currentPage3: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 5,
        }
    },
    methods: {
        handleCurrentChange(value) {
            this.$emit('HandeleCChange', value);
        },
        jumpFirstPage() {
            this.$refs.pagination.handleCurrentChange(1);
        },
        jumpLastPage() {
            let pages = this.$refs.pagination
            pages.handleCurrentChange(3);
        }
    }
}
</script>
<style lang="less" scoped>
.bottom-page-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    >p {
        margin: 0;
    }
}
</style>