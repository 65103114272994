<template>
   <ul class="tab-bar">
      <li :class="{ 'active': isActiveIndex == index }" v-for="(item, index) in tabList" :key="index" @click="changeTab(index, item)">
         <div>{{ item.label }}</div>
      </li>
   </ul>
</template>

<script >

export default {
   props: {
      tabList: {
         type: Array,
         default: () => []
      },
      getTabItem: {
         type: Object,
         default: () => {}
      }
   },
   data() {
      return {
         isActiveIndex: 0
      }
   },
   methods: {
      changeTab(index, item) {
         //  debugger
         this.isActiveIndex = index
         this.$emit('getTabItem', item);
      }
   }
}

</script>

<style lang="less" scoped>
.setFontSize(@size, @color) {
   font-size: @size;
   font-family: MicrosoftYaHei;
   color: @color;
}

.tab-bar {
   li {
      width: 12.19vw;
      height: 6.46vw;
      background-color: #F8F8FF;
      text-align: center;
      float: left;
      line-height: 6.46vw;
      .setFontSize(1.11rem, #666666);
      cursor: pointer;
   }

   li.active {
      background: linear-gradient(180deg, #0066FC 0%, #0060F7 100%);
      .setFontSize(1.11rem, #fff);

      div {
         width: 100%;
         height: 100%;
         background: url('@/assets/images/virtuacontent/dspyycj.png') no-repeat;
         background-position: center 60%;
      }


   }
}</style>