
let _exceptionHandler = {
    _throttle(method, params, context) {
        method.id && clearTimeout(method.id);
        method.id = setTimeout(function () {
            method.apply(context, [params]);
        }, 500);
    },
    _logOut() {
        if (typeof NP !== 'undefined') {
            NP.serviceFactory.loginServices("logOut", {}, function (res, code) {
                if (code === 200) {
                    $router.push({ "name": "login" });
                    window.sessionStorage.setItem("isLogin", false);
                    Toolkits.MQ.monitorGroupTopicUnSubscribe();
                }
            })
        }
    },
    _logOutErrorMatch(params) {
        $tip(params['_message']);
        this._logOut();
    }
}
const codeMap = {
    '52001': (message) => {
        _exceptionHandler._throttle(_exceptionHandler._logOutErrorMatch, { _message: message }, _exceptionHandler);
    }
}
let exeMatchException = ({ errorCode = '52000', message = '服务端程序执行异常!' }) => {
    codeMap[errorCode](message);
}
export default exeMatchException