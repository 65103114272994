<template>
  <div class="trade-plaform">
    <div class="left">
      <p>@2023-2033 changexin.com 版权所有</p>
      <p>邯郸产格新科技有限公司 <a href="https://beian.miit.gov.cn/"  target="_blank">冀ICP备2023032002号-1</a> <a href= "https://beian.mps.gov.cn/#/query/webSearch?code=13040302001620\" rel="noreferrer" target="_blank">冀公网安备13040302001620</a ></p>
    </div>
    <div class="right"></div>
  </div>
</template>
<script>
export default {
  name: "plafromFooter",
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    handleUrl() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}

</script>
<style lang="less">
.trade-plaform {
  width: 100vw;
  height: 90px;
  background: #1a1a1a;
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
  .left {
    width: 100%;
    margin-left: 50px;
    font-size: 0.89rem;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 25px;
    a {
      color: #ffffff;
    }
  }
  .right {
    width: 90px;
    height: 48px;
    margin-right: 50px;
    background: url("@/assets/images/index/changexin.png") no-repeat;
    background-size: contain;
  }
}
</style>
