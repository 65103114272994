<template>
  <div class="home-contactus">
    <div class="home-contactus-top">
      <blogpost title="联系我们" content="CONTACT US"></blogpost>


      <ul>
        <li><img :src="require('@/assets/images/index/dizhi.png')" alt="" />地址：XXXXXXXXXXXX</li>
        <li><img :src="require('@/assets/images/index/youxiang.png')" alt="" />邮箱：06666888@163.com</li>
        <li><img :src="require('@/assets/images/index/dianhua.png')" alt="" />电话：0310-6888888</li>
      </ul>
    </div>
    <div class="home-contactus-bottom">

    </div>
  </div>
</template>
<script>
import blogpost from '@/components/blogpost.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    blogpost
  }
}
</script>
<style lang="less">
.home-contactus {
  background-color: #1A1A1A;
  margin: 0 auto;

  .home-contactus-top {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .blog-post {
      margin-top: 40px;
    }

    ul {
      margin: 40px 0;
      li {
        float: left;
        width: 23.44vw;
        height: 54px;
        line-height: 54px;
        border: 1px solid #676767;
        margin-left: 1.3vw;
        font-size: .89rem;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        letter-spacing: .06rem;

        img {
          margin: 0 1.2vw 0 1.09vw;
          vertical-align: sub;
        }

      }
    }
  }

  .home-contactus-bottom {
    height: 245px;
    background-image: url('@/assets/images/bg.png');
    background-size: cover;
  }
}
</style>
