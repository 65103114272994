<template>
  <ul class="img-list">
    <li v-for="(item, index) in imgList" :style="{backgroundImage: 'url(' + (item.imgUrl) + ')'}" :key="index"></li>
  </ul>
</template>

<script>
     
     export default {
        props:{
           imgList:{
            type:Array,
            default:()=>[]
           }            
        },
        data() {
            return {

            }
        }
    }
</script>

<style lang="less" scoped>
   .img-list {
      width: 72.92vw;
      height: 472px;
      li {
        width: 13.54vw;
        height: 471px;
        border-radius: 15px;
      margin-top: 60px;
      margin-left: 1.25vw;

        float: left;
        background-size: cover;
        
      }
      li:nth-of-type(1)  {
        margin-left: 0;
      }
   }
</style>